body {
  margin: 0;
  background-color: black;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1, h2, h3, h4, h5, p, li{
  color: white;
  font-family:serif;
}
.page-container {
  position: relative;
  min-height: 100vh;
}
.content-wrap {
  padding-bottom: 2.5rem;    /* Footer height */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#myVideo {
  display: block;
  position: fixed;
  z-index: -1;
  width: 100vw;
  box-shadow: 0px 12px 20px rgb(0, 0, 0);
}
.header {
  display: block;
  position: relative;
  background-color: black;
  box-shadow: 0px 19px 13px rgb(0 0 0);
}
.header img{
  width: 100%;
  margin-left: 20%
}
.countDown-container{

  position: relative;
  max-width: 500px;
  max-height: 500px;
  padding-bottom: 4rem;
  padding-top: 7rem;
  margin: 0 auto;

}
.countDown-top{
  width: 100%;
  margin-bottom: 10%;
}
.countDown-top p{
  display: inline-flex;
}
.countNumberDays{
  font-size: 140pt;
  margin: 0;
} 
.countTextDays{
  font-size: 15pt;
  margin: 0;
} 
.countNumber{
  font-size: 50pt;
  margin: 0;
} 
.countText{
  font-size: 20pt;
  margin: 0;
} 
.countDown-bottom {
  width: 100%;
  display: flex;
}
.countDown-child {
  flex: 1;
}
.countDown-bottom:first-child {
  margin-right: 20px;
} 
.blog {
  margin-top: 10%;
  margin-bottom: 10%;
  padding-top: 10px;
  background-color: rgb(48, 48, 48);
  box-shadow: 0px 10px 5px rgb(48 48 48), 0px -6px 4px rgb(48 48 48);
}
.blogPost {
  display: flex;
  background-color: #a212a7;
  margin: 0 auto;
  margin-top: 3%;
  min-width: 200px;
  max-width: 700px;
  border-bottom: solid 3px rgb(22, 22, 22);
  border-radius: 15px;
}
.blogPost-child {
  flex: 1;
}
.blogPost img{
  margin-left: 2%;
  width: 15rem;
}
.blogInfo li{
  color: whitesmoke;
  margin: 0;
  padding: 0;
}
.blog h1{
  font-size: 30pt;
}
.blogInfoTitle:hover {
  opacity: 60%;
}
.blogTitle{
  font-size: 15pt;
  text-decoration: underline;
  text-decoration-color: whitesmoke;
  text-transform: uppercase;
}
.blogRecord {
  background-color: #a212a7;
  margin: 0 auto;
  margin-top: 3%;
  min-width: 200px;
  max-width: 700px;
  display: grid;
  
  grid-gap: 20px;
  border-bottom: solid 3px rgb(22, 22, 22);
  border-radius: 15px;
} 
.blogRecord li{
  color: whitesmoke;
  margin: 0;
  padding: 0;
}
.blogMessage {
  text-align: initial;
  margin: 0 15px 0 15px;
}
.navbar-container {
  display: flex;
}
.navbar-container-child {
  flex: 1;
}
.navbar-container ul{
  margin-top: auto;
  margin-left: auto;
}
.navbar-nav li{
  display: inline;
  margin: 0px 5px 0px 5px;
  font-size: 1.2rem;
}
.nav-link-activated {
  background: rgb(225, 20, 252);
  color: #ffffff; 
  padding: 4px;
  border-radius: 10%;
}
.nav-link-unactivated {
  background: rgb(100, 100, 100);
  color: #ffffff; 
  padding: 4px;
  border-radius: 10%;
}
.navbar-nav li:hover {
  opacity:60%;
}
code {
  font-family: MyFancyCustomFont, monospace;
  font-size: inherit;
}

/* Code in text */
p > code,
li > code,
dd > code,
td > code {
  background: #868686;
  word-wrap: break-word;
  box-decoration-break: clone;
  padding: .1rem .3rem .2rem;
  border-radius: .2rem;
}
.footer {
  position:absolute;
  bottom:0;
  width:100%;
  background-color: rgb(0, 0, 0);
  box-shadow: 0px -15px 17px rgb(0 0 0);
}
.socialLogo img{
  border-radius: 50%;
  width: 40px;
}
.socialStatic {
  position: absolute;
  background: black;
}
.socialStatic:hover {
  opacity:0;
}
.tile-container {
  margin: 0 auto;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}
.stats {
  margin-top: 10%;
  margin-bottom: 10%;
}
.tile-child {
  flex: 1;
}
.gauge-tile {
  height: 100px;
  width: 250px;
  margin: 3 12px;
  border: #a81a1a;
  border-bottom: solid 3px rgb(225, 20, 252);
  border-radius: 15px;
}
.gauge-tile h1{
  font-size: 1.5rem;
}

@media screen and (max-width: 600px) {
  .header img{
    width: 80%;
  }
  .blogRecord {
    margin: 0 auto;
    margin-top: 15%;
    min-width: 100px;
    max-width: 350px;
  } 
  .blogMessage{
    max-width: 320px;
  } 
  .navbar-nav li{
    font-size: .8rem;
  }
  .blogPost img{
    margin-left: 2%;
    width: 5rem;
  }
}